import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Background from "../components/background"
import Noise from "../components/noise"

import "../scss/global.scss"
import * as styles from "../scss/404.module.scss"

const NotFoundPage = () => {

  return (
    <>
      <Helmet>
        <title>Memojis' Roulette</title>
      </Helmet>
      <Background css={["background", "withImage"]} />
      <Background css={["background", "withGradient", "blackAndWhite"]} />
      <main className={styles.main}>
        <h1>404</h1>
        <Link to="/" className="underline">Revenir à l'accueil</Link>
      </main>
      <Noise />
    </>
  )
}

export default NotFoundPage